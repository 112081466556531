
let initialState={
    isInModifyMode:false
}

const formModeReducer=(state=initialState,action)=>{
    switch(action.type){
        case "changeFormMode":
            return {
                isInModifyMode:action.value
            }
        default:
            return {
                isInModifyMode: false
            }
    }
};

export default formModeReducer;
