import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, Spin } from "antd";
import axios from "../config/config";
import { message } from "antd/es";
import { AxiosError, AxiosResponse } from "axios";
import { useSelector } from "react-redux";
import PostAccountSelection from "./postAccountSelection";

const { Option } = Select;

export interface IFormInfo {
  product_id: string;
  deliver_to: string;
  country: string;
  //province_city: [],
  province: string;
  city: string;
  //district: string;
  address: string;
  phone: string;
  consignment_weight: string;
  contents: string;
  //unit_values: string;
  value: string;
  shipment_id?: string;
  item_id?: string;
  quantity?: string;
  postcode?: string;
  cover_amount?: number;
  reference1?: string;
  reference2?: string;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
export const formConfig = {
  product_id: [
    {
      id: "ECM8",
      code: "INTL EXPRESS MERCH/ECI MERCH",
    },
    {
      id: "ECD8",
      code: "INTL EXPRESS DOCS/ECI DOCS",
    },
    {
      id: "PTI8",
      code: "INTL STANDARD/PACK & TRACK",
    },
  ],
  Country: [
    {
      id: "CN",
      countryName: "China",
    },
    {
      id: "HK",
      countryName: "Hong Kong",
    },
    {
      id: "TW",
      countryName: "Taiwan",
    },
    {
      id: "MO",
      countryName: "Macao",
    },
    {
      id: "JP",
      countryName: "Japan",
    },
    {
      id: "SG",
      countryName: "Singapore",
    },
    {
      id: "US",
      countryName: "US",
    },
    {
      id: "NZ",
      countryName: "New Zealand",
    },
  ],
  product_classification: [
    {
      id: "1",
      code: "Gift",
    },
    {
      id: "2",
      code: "Commercial Sample",
    },
    {
      id: "3",
      code: "Other",
    },
    {
      id: "4",
      code: "Returned Goods",
    },
    {
      id: "5",
      code: "Sale of Goods",
    },
  ],
};
const AppCreateShipment = ({
                             columnState,
                             modalVisible,
                             refreshTable,
                             modalState,
                           }: {
  columnState: IFormInfo;
  modalVisible: Function;
  refreshTable: Function;
  modalState: boolean;
}) => {
  const [form] = Form.useForm();
  //@ts-ignore
  const formOthers = useSelector((state) => state.formOthers);
  const [loading, setLoading] = useState({
    loadingText: "",
    loadingStatus: false,
  });
  //@ts-ignore
  const formMode = useSelector((state) => state.formMode);
  const [formInfo, setFormInfo] = useState<IFormInfo>({
    product_id: "ECM8",
    deliver_to: "",
    country: "",
    //province_city: [],
    province: "",
    city: "",
    //district: "",
    address: "",
    phone: "",
    consignment_weight: "",
    contents: "",
    //unit_values: "",
    value: "",
    item_id: "",
    quantity: "",
    postcode: "",
    cover_amount: 0,
    reference1: "",
    reference2: "",
  });

  useEffect(() => {
    console.log("columnState", columnState);
    console.log("isInModifyMode", formMode.isInModifyMode);
    if (formMode.isInModifyMode) {
      const values: string[] = Object.values(columnState).filter(Boolean);
      if (values.length > 0) {
        console.log("create shipment", columnState);
        setFormInfo(columnState);
        setTimeout(() => {
          form.resetFields();
        }, 0);
        return;
      }
    }
  }, [columnState.shipment_id]);

  const handleUpdateShipment = () => {
    console.log("formInfo", formInfo);
    axios
        .put(`update-shipment/${formInfo.shipment_id}`, { ...formInfo })
        .then((res: AxiosResponse) => {
          console.log("res", res);
          if (res.data.success) {
            message.success(res.data.msg);
            modalVisible(false);
            refreshTable();
          }
        })
        .catch((error: AxiosError) => {
          console.log("update-shipment-error", error.message);
        });
  };

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    setLoading({ loadingText: "正在创建订单，请勿关闭", loadingStatus: true });
    Object.assign(values, {
      post_account_number: formOthers.selectedPostAccount,
    });
    axios
        .post("create-aushipment", values)
        .then((res) => {
          console.log("res", res);
          if (res.data.success) {
            message.success(res.data.msg);
            setFormInfo({
              product_id: "",
              deliver_to: "",
              country: "",
              //province_city: [],
              province: "",
              city: "",
              //district: "",
              address: "",
              phone: "",
              consignment_weight: "",
              contents: "",
              //unit_values: "",
              value: "",
              quantity: "",
              postcode: "",
              cover_amount: 0,
              reference1: "",
              reference2: "",
            });
            modalVisible(false);
            refreshTable();
            //form.resetFields();
            return;
          }
          return message.error(res.data?.data ?? "出错了，请稍后重试！");
        })
        .catch((e) => {
          console.log("save-address error", e);
        });
    console.log("formInfo", formInfo);
  };

  const handleFormOnChange = (e, value) => {
    console.log("value", value);
    let newVal = { ...formInfo };

    let eventvalue = e;
    if (e.hasOwnProperty("target")) {
      eventvalue = e.target.value;
      console.log("e.target", eventvalue);
    }
    newVal[value] = eventvalue;
    console.log("newVal", newVal);
    setFormInfo(() => newVal);
  };

  const showTotalValues = (): number => {
    let totalValue = parseInt(formInfo.quantity) * parseInt(formInfo.value);
    return Number.isNaN(totalValue) ? 0 : totalValue;
  };
  return (
      <Spin tip={loading.loadingText} spinning={loading.loadingStatus}>
        <Form
            {...formItemLayout}
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={formInfo}
            scrollToFirstError
            labelAlign={"left"}
        >
          <Form.Item name="post_account" label="Product Account Number">
            <PostAccountSelection />
          </Form.Item>
          <Form.Item name="product_id" label="Product ID">
            <Select
                showSearch
                style={{ maxWidth: "20rem" }}
                placeholder="Select a product id"
                onChange={(e) => handleFormOnChange(e, "product_id")}
                value={formInfo.product_id}
            >
              {formConfig.product_id.map((el, index: number) => {
                return (
                    <Option
                        value={el.id}
                        onChange={(e) => console.log(e)}
                        key={index}
                    >
                      {el.code}
                    </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Form.Item
              name="deliver_to"
              label="收件人姓名:"
              rules={[
                {
                  required: true,
                  message: "Please input your delivery to address!",
                },
              ]}
              hasFeedback
          >
            <Input
                value={formInfo.deliver_to}
                onChange={(e) => handleFormOnChange(e, "deliver_to")}
            />
          </Form.Item>

          <Form.Item
              name="country"
              label="国家"
              dependencies={["password"]}
              hasFeedback
          >
            <Select
                showSearch
                style={{ maxWidth: "20rem" }}
                placeholder="选择国家"
                value="1"
                onChange={(e) => handleFormOnChange(e, "country")}
            >
              {formConfig.Country.map((el, index: number) => {
                return (
                    <Option value={el.id} key={index}>
                      {el.countryName}
                    </Option>
                );
              })}
            </Select>
          </Form.Item>

          {/*<Form.Item name="residence" label="省份/城市/区域">*/}
          {/*    <Cascader options={residences} value={formInfo.province_city} onChange={(e)=>console.log('e',e)}/>*/}
          {/*</Form.Item>*/}

          <Form.Item name={"province"} label={"省份"}>
            <Input
                style={{ width: "100%" }}
                value={formInfo.province}
                onChange={(e) => handleFormOnChange(e, "province")}
            />
          </Form.Item>

          {/*<Form.Item name={"city"} label={"城市"}>*/}
          {/*  <Input*/}
          {/*    style={{ width: "100%" }}*/}
          {/*    value={formInfo.city}*/}
          {/*    onChange={(e) => handleFormOnChange(e, "city")}*/}
          {/*  />*/}
          {/*</Form.Item>*/}

          <Form.Item name={"city"} label={"市"}>
            <Input
                style={{ width: "100%" }}
                value={formInfo.city}
                onChange={(e) => handleFormOnChange(e, "city")}
            />
          </Form.Item>

          <Form.Item name="address" label="详细地址">
            <Input
                style={{ width: "100%" }}
                value={formInfo.address}
                onChange={(e) => handleFormOnChange(e, "address")}
            />
          </Form.Item>

          <Form.Item name="postcode" label="邮编">
            <Input
                style={{ width: "100%" }}
                value={formInfo.postcode}
                onChange={(e) => handleFormOnChange(e, "postcode")}
            />
          </Form.Item>

          <Form.Item
              name="phone"
              label="手机号"
              rules={[
                { required: true, message: "Please input your phone number!" },
                { len: 11, message: "手机号码最长为11位" },
              ]}
          >
            <Input
                style={{ width: "100%" }}
                value={formInfo.phone}
                onChange={(e) => handleFormOnChange(e, "phone")}
                maxLength={11}
            />
          </Form.Item>

          <Form.Item name="consignment_weight" label="包裹重量">
            <Input
                value={formInfo.consignment_weight}
                onChange={(e) => handleFormOnChange(e, "consignment_weight")}
            />
          </Form.Item>

          <Form.Item name="contents" label="邮寄商品明细">
            <Input
                value={formInfo.contents}
                onChange={(e) => handleFormOnChange(e, "contents")}
            />
          </Form.Item>

          <Form.Item name="quantity" label="邮寄商品数量">
            <Input
                value={formInfo.quantity}
                onChange={(e) => handleFormOnChange(e, "quantity")}
            />
          </Form.Item>

          {/*<Form.Item name="unit_value" label="Unit Value">*/}
          {/*  <Input*/}
          {/*    value={formInfo.unit_values}*/}
          {/*    onChange={(e) => handleFormOnChange(e, "unit_values")}*/}
          {/*  />*/}
          {/*</Form.Item>*/}
          <Form.Item name="value" label="单个物品的价格">
            <Input
                value={formInfo.value}
                onChange={(e) => handleFormOnChange(e, "value")}
            />
          </Form.Item>

          <Form.Item name="" label="商品总价值：">
            {showTotalValues()}
          </Form.Item>
          <p style={{ color: "red" }}>
            {showTotalValues() > 2000
                ? "该商品总价值烧过2000澳元，需要export number."
                : ""}
          </p>
          <Form.Item name="cover_amount" label="保险金额">
            <Input
                value={formInfo.cover_amount}
                onChange={(e) => handleFormOnChange(e, "cover_amount")}
                type={"number"}
            />
          </Form.Item>

          <Form.Item name="reference1" label="备注1">
            <Input
                value={formInfo.reference1}
                onChange={(e) => handleFormOnChange(e, "reference1")}
            />
          </Form.Item>

          <Form.Item name="reference2" label="备注2">
            <Input
                value={formInfo.reference2}
                onChange={(e) => handleFormOnChange(e, "reference2")}
            />
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button
                type="primary"
                htmlType="submit"
                disabled={formMode.isInModifyMode}
            >
              提交包裹信息
            </Button>

            <Button
                type="primary"
                onClick={handleUpdateShipment}
                disabled={!formMode.isInModifyMode}
            >
              修改shipments
            </Button>
          </Form.Item>
        </Form>
      </Spin>
  );
};

export default AppCreateShipment;
