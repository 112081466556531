import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import About from "../Pages/About";
import AppLogin from "../Pages/Login";
import AppHeader from "../Pages/AppHeader/AppHeader";
import LogRocket from "logrocket";
const RootNavigation: React.FC = () => {
    LogRocket.init("nx96xe/aupost");
    return (
        <Router>
            <Switch>
                <Route path="/" exact component={AppLogin} />
                <Route path="/manage" component={AppHeader} />
                <Route path="/about" component={About} />
            </Switch>
        </Router>
    );
};

export default RootNavigation;