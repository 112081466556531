import configStore from "../store/configStore";

const setRequestHeadersInterceptor = async (config) => {
    await setTokenHeader(config);
    setAccountNumberHeader(config);
    console.log("config", config);
    return config;
};

const setTokenHeader = async (config) => {
    let token = await localStorage.getItem("aupost-token");
    if (token) {
        config.headers["x-auth-token"] = `${token}`;
    }
};

const setAccountNumberHeader = (config) => {
    const state = configStore().getState();
    //let selectedAccountNumber = "1003471228";
    let selectedAccountNumber = state.formOthers.selectedPostAccount;
    //let selectedAccountNumber = state.formOthers.selectedPostAccount;
    // if (selectedAccountNumber.length !== 10) {
    //   selectedAccountNumber = selectedAccountNumber.padStart(10, "0");
    // }

    console.warn("setAccountNumber", state);
    config.headers["Account-Number"] = selectedAccountNumber;
};
export default setRequestHeadersInterceptor;
