import React, { useEffect, useState } from "react";
import axios from "../config/config";
import { AxiosResponse } from "axios";
import { message } from "antd";
import { IShipment } from "../types/shipments/shipments";

const useFetchShipment = ({
                            isPast,
                            orderId,
                          }: {
  isPast?: boolean;
  orderId?: string;
}) => {
  const [shipment, setShipments] = useState<IShipment[]>([]);
  const requestTableContent = () => {
    let url = "get-shipment";
    if (isPast || orderId) {
      url = url + "?";
    }
    if (isPast) {
      url = url + `past=1`;
    }
    if (orderId) {
      url = url + `orderId=${orderId}`;
    }

    axios
        .get(url)
        .then((res: AxiosResponse) => {
          console.log("get-shipment", res);
          if (res.data.results === 0) {
            return message.info("No Past shipments are found for you.");
          }
          setShipments(() => res.data.results);
        })
        .catch((e) => {
          console.log("e", e.message);
        });
  };
  useEffect(() => {
    requestTableContent();
  }, []);
  return [shipment, requestTableContent, setShipments];
};

export default useFetchShipment;