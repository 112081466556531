import { Table } from "antd";
import React from "react";
import { IShipment } from "../types/shipments/shipments";

const TableContents = ({
                           shipments,
                           columns,
                           tableLoading,
                           setShipmentToOrder,
                           pageSize,
                           setPageSize,
                       }: {
    shipments: IShipment[];
    columns: any[];
    tableLoading: boolean;
    setShipmentToOrder?: Function;
    pageSize?: number;
    setPageSize?: Function;
}) => {
    const showShipments = () => {
        if (shipments.length === 0) {
            return <p>you do not have any shipments</p>;
        }
        return (
            <Table
                rowKey={"shipment_id"}
                dataSource={shipments}
                columns={columns}
                loading={tableLoading}
                pagination={{
                    pageSize: pageSize ?? 10,
                    pageSizeOptions: ["10", "20", "50", "100"],
                    showSizeChanger: true,
                    showTotal: (number) => {
                        console.log("number", number);
                        return `Total:${number}`;
                    },
                    onShowSizeChange: (current, size) => {
                        console.log("current", current);
                        console.log("size", size);
                        setPageSize(size);
                    },
                    position: ["topLeft"],
                }}
                rowSelection={{
                    onChange: (selectedRowKeys, selectedRows: any) => {
                        console.log(
                            `selectedRowKeys: ${selectedRowKeys}`,
                            "selectedRows: ",
                            selectedRows
                        );
                        const selectedShipments = [];
                        selectedRows.forEach((row) => {
                            selectedShipments.push({
                                shipment_id: row.shipment_id,
                                label_url: row.label_url,
                                item_id: row.item_id,
                            });
                        });
                        console.log("shiptoorder", selectedShipments);
                        setShipmentToOrder(() => selectedShipments);
                    },
                }}
            />
        );
    };
    return <>{showShipments()}</>;
};
export default TableContents;
