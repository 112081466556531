import axios from "axios";
import setRequestHeadersInterceptor from "./interceptors";

/*
 * cancel axios request to prevent memory leak
 * */

export const source = axios.CancelToken.source();

const instance = axios.create({
    baseURL: "http://webapi2.jotec.com.au/api/v1/", //'api/v1'
    //baseURL:'api/v1',
    timeout: 800000, //control whether cancel this request if waiting time is longer than this
    headers: { "X-Custom-Header": "foobar" },
});
instance.interceptors.request.use(setRequestHeadersInterceptor, (error) => {
    return Promise.reject(error);
});
export default instance;
