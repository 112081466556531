const initialState={
    charge_code: "",
    delivery_to: "",
    country: "",
    //province_city: [],
    province:'',
    city:'',
    district:'',
    address: "",
    phone: "",
    consignment_weight: "",
    contents: "",
    unit_values: "",
    values: "",
};
enum FormInfoTypes {
    handleOnChange='handleOnChange'
}
export const handleFormOnChange=(value)=>{
    return {
        type: FormInfoTypes.handleOnChange,
        payload:value
    }
};
export const formInfoReducer=(state=initialState,action)=>{
    switch(action.type){
        case FormInfoTypes.handleOnChange:
            return "";
        default:
            return state;
    }
};

