import { combineReducers, applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { todosReducer } from "./reducer/todoReducer/todoReducer";
import { formInfoReducer as formInfo } from "./reducer/formInfo/formInfoReducer";
import { uploadTimesReducer } from "./reducer/uploadTimes/uploadTimesReducer";
import formModeReducer from "./reducer/formMode";
import { formReducer } from "./reducer/formReducer/formReducer";
import LogRocket from "logrocket";

/**
 |--------------------------------------------------
 | config rootReducer
 |--------------------------------------------------
 */
const rootReducer = combineReducers({
  todo: todosReducer,
  formInfo: formInfo,
  uploadTimes: uploadTimesReducer,
  formMode: formModeReducer,
  formOthers: formReducer,
});

/**
 |--------------------------------------------------
 | 自定义了一个logger middle 开始
 |--------------------------------------------------
 */
const logger = (store) => (next) => (action) => {
  if (typeof action === "function") {
    console.log("dispatching a function");
  } else {
    console.log("dispatching an action", action);
  }
  const result = next(action);
  console.log("nextState", store.getState(), result);
};
/**
 |--------------------------------------------------
 | logger middleware 结束
 |--------------------------------------------------
 */

/**
 |--------------------------------------------------
 | connect to redux store in chrome
 |--------------------------------------------------
 */
let composeEnhancer = compose;
//@ts-ignore
composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(thunk, logger, LogRocket.reduxMiddleware()))
);

const configStore = () => {
  return store;
};
export default configStore;
