import React, { useEffect, useState } from "react";
import axios from "../../../config/config";
import { AxiosError, AxiosResponse } from "axios";
import {
  Button,
  Divider,
  message,
  Modal,
  Table,
  Upload,
  Spin,
  Collapse,
} from "antd";
import AppCreateShipment, {
  IFormInfo,
} from "../../../component/AppCreateShipment";
import {
  CopyOutlined,
  DeleteOutlined,
  PlusOutlined,
  ReloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import readExcel from "read-excel-file";
import { useDispatch, useSelector } from "react-redux";
import PostAccountSelection from "../../../component/postAccountSelection";
import moment from "moment";
import TableContents from "../../../component/TableContent";
import TableSearch from "../../../component/TableSearch";
import ExportToExcel from "../../../component/ExportToExcel/Export";

interface excelShipment {
  name: string;
  province: string;
  city: string;
  address: string;
  postcode: number;
  phone: number;
  weight: number;
  description: string;
  quantity: number;
  value: number;
}

const productId = [
  {
    id: "ECM8",
    code: "INTL EXPRESS MERCH/ECI MERCH",
    desc: "INT EXPRESS M 特快件",
  },
  {
    id: "ECD8",
    code: "INTL EXPRESS DOCS/ECI DOCS",
    desc: "INT EXPRESS D 特快信件",
  },
  {
    id: "PTI8",
    code: "INTL STANDARD/PACK & TRACK",
    desc: "INT STANDARD 标准件",
  },
];

function getProductId(name: string) {
  const targetProductInfo = productId.find((el) => el.desc == name);
  return targetProductInfo.id;
}

const AppShipmentStatus = () => {
  //@ts-ignore
  const uploadTimes = useSelector((state) => state.uploadTimes);

  const selectedPostAccountNumber = useSelector(
      //@ts-ignore
      (state) => state.formOthers.selectedPostAccount
  );
  //@ts-ignore
  const dispatch = useDispatch();

  useEffect(() => {
    RefreshTable();
  }, [selectedPostAccountNumber]);
  const columns = [
    {
      title: "Label Url",
      dataIndex: "label_url",
      render: (text) => {
        //console.log('label', text)
        if (text) {
          return (
              <a href={text} target={"_blank"}>
                View Label
              </a>
          );
        }
        return "暂无label";
      },
    },
    {
      title: "收件人",
      dataIndex: "deliver_to",
    },
    {
      title: "电话",
      dataIndex: "phone",
    },
    {
      title: "详细地址",
      dataIndex: "address",
    },
    {
      title: "重量",
      dataIndex: "consignment_weight",
      width: "100px",
    },
    {
      title: "包裹商品明细",
      dataIndex: "contents",
      width: "140px",
    },
    {
      title: "Value",
      dataIndex: "value",
    },
    {
      title: "Sender ID",
      dataIndex: "sender_email",
    },
    {
      title: "Action",
      render: (text) => {
        return (
            <div style={{ maxWidth: "100px" }}>
              {/*<Button type={"primary"} onClick={() => handleShipment(text)}>*/}
              {/*  Ship*/}
              {/*</Button>*/}
              <Button
                  type={"primary"}
                  onClick={() => handleCreateLabel(text)}
                  style={{ width: "100%", marginBottom: "5px" }}
              >
                Create Label
              </Button>
              <Button
                  type={"ghost"}
                  onClick={() => handleDeleteShipment(text)}
                  style={{ width: "100%", marginBottom: "5px" }}
              >
                Delete
              </Button>
              <Button
                  type={"default"}
                  onClick={() => handleCheckShipment(text)}
                  style={{ width: "100%", marginBottom: "5px" }}
              >
                Modify
              </Button>
              <Button
                  type={"dashed"}
                  onClick={() => handleForceDelete(text)}
                  style={{
                    width: "100%",
                    marginBottom: "5px",
                    color: "red",
                  }}
              >
                Force Delte
              </Button>
            </div>
        );
      },
    },
    {
      title: "创建日期",
      dataIndex: "create_date",
      render: (text) => {
        return moment(text.create_date).format("MMMM Do YYYY, h:mm:ss a");
      },
      width: "150px",
    },
    {
      title: "Tracking Id",
      dataIndex: "tracking_details_consignment_id",
    },

    {
      title: "Internal ID",
      dataIndex: "internal_id",
    },

    // {
    //     title: "Product ID",
    //     dataIndex: "product_id",
    // },

    // {
    //   title: "Shipment ID",
    //   dataIndex: "shipment_id",
    // },
  ];

  const handleCheckShipment = (text: IFormInfo) => {
    //TODO:check this logic
    console.log("text", text);
    dispatch({ type: "changeFormMode", value: true });
    setVisible(() => true);
    setColumnState(text);
  };
  const [isPageLoading, setPageLoading] = useState<boolean>(false);
  const [pageLoadingContent, setLoadingContent] = useState<string>("");
  const [pagination, setPagination] = useState({ pageSize: 10 });

  const handleDeleteShipment = async (text: IFormInfo) => {
    const shipmentId = text.shipment_id;
    await deleteShipment(shipmentId);
  };

  const deleteShipment = async (shipmentId: string | string[]) => {
    try {
      let results: AxiosResponse = await axios.delete(
          `delete-shipment/${shipmentId}`
      );
      console.log("res", results);
      if (results.data?.success) {
        RefreshTable();
        return message.success(results.data?.msg);
      }
      return message.error(results?.data?.msg);
    } catch (e) {
      console.log("e", e);
      return message.error(e.message);
    }
  };

  const handleForceDelete = async (text: IFormInfo) => {
    const shipmentId = text.shipment_id;
    try {
      let result = await axios.delete(`force-delete-shipment/${shipmentId}`);
      console.log("result", result);
      if (result.data.success) {
        RefreshTable();
        return message.success(result.data.message);
      }
      return message.error(result.data.msg);
    } catch (e) {
      console.log("e", e);
      return message.error(e.message);
    }
  };

  const createLabel = async (
      data: { shipment_id: string; item_id: string }[]
  ) => {
    try {
      let response = await axios.post("create-label", {
        shipmentToLabel: data,
      });
      console.log("create-label-response", response);
      if (response.data?.length > 0) {
        message.success("label 创建成功");
        RefreshTable();
        return;
      }
      return message.error(response?.data?.msg ?? "出错了，请稍后重试！");
    } catch (e) {
      console.log("e", e);
      return message.error(e.message);
    }
  };
  const handleCreateLabel = async (text: IFormInfo) => {
    const { shipment_id, item_id } = text;
    if (shipment_id === undefined || item_id === undefined) {
      message.error("请确定这个shipment有可靠的shipment id");
    }
    console.log("createlabel", text);
    console.log("shipment_id", shipment_id);
    let data = [
      {
        shipment_id,
        item_id,
      },
    ];
    await createLabel(data);
  };
  const [shipments, setShipments] = useState([]);
  const [actualUploadtimes, setActualUploadTimes] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [columnState, setColumnState] = useState<IFormInfo>({
    product_id: "",
    deliver_to: "",
    country: "",
    //province_city: [],
    province: "",
    city: "",
    address: "",
    phone: "",
    consignment_weight: "",
    contents: "",
    //unit_values: "",
    value: "",
    cover_amount: 0,
    reference1: "",
    reference2: "",
  });
  const [selectedShipmentToOrders, setShipmentToOrder] = useState([]);
  const requestTableContent = () => {
    axios
        .get("get-shipment")
        .then((res: AxiosResponse) => {
          console.log("get-shipment", res);
          if (res.data.results === 0) {
            return message.info("No shipments are found for you.");
          }
          setShipments(() => res.data.results);
          setTableLoading(() => false);
        })
        .catch((e) => {
          console.log("e", e.message);
        });
  };
  useEffect(() => {
    requestTableContent();
  }, []);

  useEffect(() => {
    const { shouldUploadTimes, actualUploadTimes } = uploadTimes;
    console.log("shouldUploadtime", shouldUploadTimes, actualUploadTimes);
    if (
        shouldUploadTimes !== 0 &&
        actualUploadTimes !== 0 &&
        shouldUploadTimes === actualUploadTimes
    ) {
      dispatch({ type: "resetUploadTimes" });
      setPageLoading(false);
      message.success("Excel表格中的shipments都已经已经创建成功");
      RefreshTable();
    }
  }, [uploadTimes.actualUploadTimes]);

  const createShipment = (data, times) => {
    setLoadingContent(() => "正在创建订单...");
    axios
        .post("create-aushipment", data)
        .then((res: AxiosResponse) => {
          console.log("res", res);
          setActualUploadTimes(() => actualUploadtimes + 1);
          dispatch({ type: "actualUploadTimes", payload: 1 });
        })
        .catch((e: AxiosError) => {
          console.log("create-aushipment error", e);
          message.error(e.message);
          setPageLoading(false);
        });
  };
  const handleExcelFile = async (file: File) => {
    console.log("file", file);
    setPageLoading(true);
    setLoadingContent(() => "正在读取表格,请稍等...");
    let reader = new FileReader();
    reader.onload = async (e) => {
      readExcel(reader.result).then(async (rows) => {
        let createShipmentPromiseCollection: Promise<any>[] = [];
        console.log("rows", rows);
        let transferedRow = rows
            // .map((el) => el.filter(Boolean))
            //   .filter((el) => el.length > 0)
            .slice(1);
        dispatch({ type: "shouldUploadTimes", payload: rows.length });
        console.log("transferedRow", transferedRow);
        for (let el of transferedRow) {
          //todo:check this function and change unit value and auto show total value
          let data = {
            internal_id: el[0],
            product_id: getProductId(el[1]),
            deliver_to: el[2],
            country: el[3],
            //province_city: [],
            province: el[4],
            city: el[5],
            //district: el[4],
            address: el[6],
            postcode: el[7],
            phone: el[8],
            contents: el[9],
            quantity: el[10],
            // unit_value: el[10],
            value: el[11],
            consignment_weight: el[12].toString(),
            cover_amount: el[13],
            reference1: el[14],
            reference2: el[15],
          };
          console.log("data", data);
          //createShipment(data, actualUploadtimes);
          createShipmentPromiseCollection.push(
              axios.post("create-aushipment", data)
          );
        }
        try {
          let result = await Promise.allSettled(
              createShipmentPromiseCollection
          );
          console.log("create shipemnts from excel", result);
          if (
              result.filter((el) => el.status === "fulfilled").length ===
              createShipmentPromiseCollection.length
          ) {
            setPageLoading(() => false);
            return message.success("Excel表格中的shipments都已经已经创建成功!");
          }
          console.log("result");
          return message.warning("excel表格中的部分shipment没有成功创建！");
        } catch (e) {
          console.log("create-aushipment-from-excel-has-errors", e);
          message.error(e.message);
          setPageLoading(false);
          return;
        }
      });
    };
    reader.readAsArrayBuffer(file);
    return false;
  };

  const HandleCreatOrders = () => {
    setTableLoading(() => true);
    console.log("selectedShipmentsToOrder", selectedShipmentToOrders);
    let isPass =
        selectedShipmentToOrders.map((el) => el.label_url).filter(Boolean)
            .length !== 0;
    console.log("isPass", isPass);
    if (isPass) {
      axios
          .put("create-order", { selectedShipments: selectedShipmentToOrders })
          .then((res: AxiosResponse) => {
            console.log("create-order-response", res.data);
            if (res.data.success) {
              RefreshTable();
              return message.success(res.data.msg);
            }
            return message.info("创建order遇到问题，请稍后重试.");
          })
          .catch((e: AxiosError) => {
            console.log("create-order-error", e);
            setTableLoading(() => false);
            return message.error(e.message);
          });
    } else {
      return message.error("请确保所选择的shipment已经生成label");
    }
  };

  const RefreshTable = () => {
    setTableLoading(() => true);
    requestTableContent();
  };

  const handleBatchDelete = async () => {
    const shipmentIds = selectedShipmentToOrders.map((shipment) => {
      return shipment.shipment_id;
    });
    console.log("shipmentIds", shipmentIds);
    await deleteShipment(shipmentIds);
  };

  const handleBatchCreateLabel = async () => {
    console.log("batch create label", selectedShipmentToOrders);
    await createLabel(selectedShipmentToOrders);
  };

  const handleSearchResult = (result) => {
    setTableLoading(() => false);
    return setShipments(() => result);
  };

  const handleTableLoading = () => {
    setTableLoading(() => true);
  };
  return (
      <Spin tip={pageLoadingContent} spinning={isPageLoading} size={"large"}>
        <p style={{ fontWeight: "bold", marginTop: "10px" }}>
          请先选择邮局账户号码：
        </p>
        <PostAccountSelection />
        {/*<p style={{ border: "5px solid black" }}>筛选/搜索功能尚未完成</p>*/}
        <Divider />
        <Button
            type={"primary"}
            onClick={HandleCreatOrders}
            style={{ marginRight: "5px" }}
        >
          <PlusOutlined /> Create Order
        </Button>
        <Button type={"default"} onClick={RefreshTable}>
          <ReloadOutlined /> Refresh
        </Button>
        <Button
            type={"primary"}
            style={{ marginLeft: "5px" }}
            onClick={handleBatchCreateLabel}
            ghost
            disabled={selectedShipmentToOrders.length === 0}
        >
          <CopyOutlined />
          批量创建Label
        </Button>
        <Button
            type={"primary"}
            danger
            style={{ marginLeft: "5px" }}
            onClick={handleBatchDelete}
            disabled={selectedShipmentToOrders.length === 0}
        >
          <DeleteOutlined />
          批量删除
        </Button>
        <Divider />

        <Button
            type={"primary"}
            onClick={() => {
              dispatch({ type: "changeFormMode", value: false });
              setVisible(() => true);
            }}
            style={{ marginRight: "1rem" }}
        >
          <PlusOutlined /> 创建邮局包裹
        </Button>

        <Upload
            //@ts-ignore
            beforeUpload={handleExcelFile}
            accept={".xlsx,.xls"}
            showUploadList={false}
        >
          <Button>
            <UploadOutlined /> 批量创建包裹(上传Excel文件)
          </Button>
        </Upload>
        <Divider />
        <Collapse defaultActiveKey={"0"}>
          <Collapse.Panel header={"Search Shipments"} key={"1"}>
            <TableSearch
                setTableFitleredContent={handleSearchResult}
                refreshTable={requestTableContent}
                setTableLoading={handleTableLoading}
                isPast={false}
            />
          </Collapse.Panel>
          <Collapse.Panel key={"2"} header={"Export To Excel"}>
            <ExportToExcel shipments={shipments} />
          </Collapse.Panel>
        </Collapse>

        <h3>
          <TableContents
              shipments={shipments}
              columns={columns}
              tableLoading={tableLoading}
              setShipmentToOrder={setShipmentToOrder}
              pageSize={pagination.pageSize}
              setPageSize={(pageSize) => setPagination({ pageSize })}
          />
        </h3>

        <Modal
            title={"Create A Shipment"}
            visible={visible}
            onCancel={() => setVisible(() => false)}
            footer={[
              <Button key="back" onClick={() => setVisible(() => false)}>
                Cancel
              </Button>,
            ]}
        >
          <AppCreateShipment
              columnState={columnState}
              modalVisible={(state: boolean) => setVisible(state)}
              refreshTable={RefreshTable}
              key={Math.random()}
              modalState={visible}
          />
        </Modal>
      </Spin>
  );
};

export default AppShipmentStatus;
