let initialState = {
  post_accounts:[],
  selectedPostAccount:"7569279",
  Country: [
    {
      id: "1",
      countryName: "China",
    },
  ],
};

enum formReducerTypes{
  changePostAccount='changePostAccount',
  initPostAccounts='initPostAccounts'
}

export const changePostAccount=(value)=>{
  console.log('changeProductId',value);
  return {
    type:formReducerTypes.changePostAccount,
    payload:value
  }
}

export const initPostAccounts=(value)=>{
  return {
    type:formReducerTypes.initPostAccounts,
    payload:value
  }
}
export const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case formReducerTypes.changePostAccount:
      return {
        ...state,
        selectedPostAccount: action.payload
      };
    case formReducerTypes.initPostAccounts:
      return {
        ...state,
        post_accounts: action.payload,
      }
    default:
      return state;
  }
};
