import React, { useEffect, useRef, useState } from "react";
import axios from "../config/config";
import { Button, Table, Modal, message } from "antd";
import { AxiosError, AxiosResponse } from "axios";
import { Divider } from "antd/es";
import moment from "moment";
import styled from "styled-components";

const Input = styled.input`
  border: 1px solid black;
  padding: 3px;
  text-align: center;
  :hover {
    cursor: pointer;
    color: mediumvioletred;
  }
`;

interface IOrder {
    number_of_items: string;
    number_of_shipments: string;
    order_creation_date: string;
    order_id: string;
    order_reference: string;
    payment_method?: string;
    shipping_methods?: string;
    total_cost: number;
    total_cost_ex_gst: number;
    total_gst: number;
    total_weight: number;
}

const Span = styled.span`
  :hover {
    cursor: pointer;
    color: dodgerblue;
    border-bottom: 1px solid red;
  }
  border: 1px solid white;
  color: dodgerblue;
`;
const AppOrder = () => {
    const [orders, setOrders] = useState<IOrder[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [modal, setModal] = useState<boolean>(false);
    const [selectedOrderId, setOrderId] = useState<string>("");
    const orderIdRef = useRef(null);
    useEffect(() => {
        axios
            .get("get-orders")
            .then((res: AxiosResponse) => {
                console.log("get orders response", res.data);
                if (res.data.success && res.data.orders.length > 0) {
                    setOrders(() => res.data.orders);
                    setLoading(() => false);
                }
            })
            .catch((e) => {
                console.log("e", e);
            });
    }, []);
    const columns = [
        // {
        //     title: "Order Url",
        //     dataIndex: "order_url",
        //     render: (text => {
        //         console.log('label', text)
        //         if (text) {
        //             return <a href={text} target={"_blank"}>View Order</a>
        //         }
        //         return '暂无Order';
        //
        //     })
        // },
        {
            title: "创建时间",
            dataIndex: "order_creation_date",
            render: (text) => {
                return moment(text).format("MMMM Do YYYY, h:mm:ss a");
            },
        },
        {
            title: "Order ID",
            dataIndex: "order_id",
            render: (order_id) => {
                return (
                    <Span
                        onClick={() => {
                            setModal(() => !modal);
                            setOrderId(() => order_id);
                        }}
                    >
                        {order_id}
                    </Span>
                );
            },
        },
        {
            title: "Total Items",
            dataIndex: "number_of_items",
        },
        {
            title: "Total Shipments",
            dataIndex: "number_of_shipments",
        },
        {
            title: "Order Reference",
            dataIndex: "order_reference",
        },
        {
            title: "Total Weight",
            dataIndex: "total_weight",
        },
        {
            title: "Total Cost",
            dataIndex: "total_cost",
        },
        {
            title: "Action",
            render: (text: IOrder) => {
                console.log("action", text);
                return (
                    <>
                        <Button
                            type={"primary"}
                            onClick={() => handleGetOrderSummary(text)}
                            size={"small"}
                        >
                            Get Order Summary
                        </Button>
                    </>
                );
            },
        },
    ];

    const handleOnClick = () => {
        orderIdRef.current.select();
        let result = document.execCommand("copy");
        if (result) {
            message.success(`${selectedOrderId}已经成功复制到剪切板！`);
        } else {
            message.error(`${selectedOrderId}复制到剪切板遇到错误！`);
        }
        //window.getSelection().removeAllRanges();
    };
    const handleGetOrderSummary = (order: IOrder) => {
        console.log("get order summary");
        if (order?.order_id) {
            axios(`get-order-summary/${order.order_id}`, {
                method: "GET",
                responseType: "blob",
                headers: { "Content-type": "application/pdf" },
            })
                .then((res: AxiosResponse) => {
                    console.log("order_summary", res);
                    let blob = new Blob([res.data], { type: "application/pdf" });
                    let fileName = `${order.order_id} order summary.pdf`;
                    let url = URL.createObjectURL(blob);
                    let link = document.createElement("a");
                    link.style.display = "none";
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link); //下载完成移除元素
                    window.URL.revokeObjectURL(url);
                })
                .catch((e: AxiosError) => {
                    console.log("order_summary_error", e);
                });
        }
    };

    const showOrdersTable = () => {
        if (orders.length !== 0) {
            return (
                <Table
                    columns={columns}
                    dataSource={orders}
                    loading={loading}
                    rowKey={"order_id"}
                />
            );
        }
        return (
            <p>
                暂无可以打印的orders，请先去创建shipments，然后create
                labels,最后点击create orders 来生产order table
            </p>
        );
    };
    return (
        <>
            {/*<p>search/filter function is not finished.</p>*/}
            <Divider />
            {showOrdersTable()}
            <Modal
                visible={modal}
                onCancel={() => setModal(() => false)}
                onOk={() => setModal(false)}
                width={1200}
            >
                <p>
                    您选中的order number是：
                    <Input
                        readOnly
                        ref={orderIdRef}
                        onClick={handleOnClick}
                        value={selectedOrderId}
                    />
                </p>
                {/*<ShipmentsTable orderId={selectedOrderId} key={selectedOrderId} />*/}
            </Modal>
        </>
    );
};

export default AppOrder;
