// eslint-disable-next-line
import React from "react";
import { Form, Input, Button, Checkbox,message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import axios from '../config/config';
import {AxiosError} from 'axios';
import {useHistory} from 'react-router-dom';
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 6 },
};
const tailLayout = {
    wrapperCol: { offset: 4, span: 12 },
};

const AppLogin: React.FC = () => {
    let history=useHistory();
    const onFinish = (values) => {
        console.log("Success:", values);
        const {username,password}=values;
        axios.post('auth',{email:username,password}).then(res=>{
            console.log('res',res)
            if(res.status===200){
                console.log('successfully logged in.')
                const token=res.data.user.token;
                if(token){
                    localStorage.setItem('aupost-token',token);
                    history.push('/manage');
                }
                return;
            }
            return message.error('Ops, there is an error');
        }).catch((e:AxiosError)=>{
            console.log('axios_auth_error',e);
            message.error('auth api server error');
        })
    };

    return (
        <>
            <Form
                {...layout}
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                style={{ marginTop: "10rem" }}
            >
                <Form.Item
                    name="username"
                    {...tailLayout}
                    rules={[{ required: true, message: "Please input your Username!" }]}
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Username"
                    />
                </Form.Item>
                <Form.Item
                    {...tailLayout}
                    name="password"
                    rules={[{ required: true, message: "Please input your Password!" }]}
                >
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>
                <Form.Item {...tailLayout}>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <button className="login-form-forgot" >
                        Forgot password
                    </button>
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="login-form-button"
                    >
                        Log in
                    </Button>
                    Or <button >register now!</button>
                </Form.Item>
            </Form>
        </>
    );
};
export default AppLogin;
