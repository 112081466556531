import React, { useEffect, useState } from "react";

import { Col, Collapse, Spin } from "antd";
import TableSearch from "./TableSearch";
import useFetchShipment from "../Hook/useFetchShipment";
import { IShipment } from "../types/shipments/shipments";
import ExportToExcel from "./ExportToExcel/Export";
import ShipmentsTable from "./GenerateTable/GenerateTable";
const PastShipment = () => {
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  // @ts-ignore
  const [shipments, requestTableContent, setShipments]: [
    IShipment[],
    Function,
    Function
  ] = useFetchShipment({ isPast: true });
  useEffect(() => {
    if (shipments.length > 0) {
      setTableLoading(() => false);
    }
  });
  return (
      <div>
        <Collapse defaultActiveKey={"0"}>
          <Collapse.Panel header={"Search Shipments"} key={"1"}>
            <TableSearch
                setTableFitleredContent={(filteredShipments) =>
                    setShipments(() => filteredShipments)
                }
                refreshTable={requestTableContent}
                setTableLoading={() => setTableLoading(() => true)}
                isPast={true}
            />
          </Collapse.Panel>
          <Collapse.Panel key={"2"} header={"Export To Excel"}>
            <ExportToExcel shipments={shipments} />
          </Collapse.Panel>
        </Collapse>

        <ShipmentsTable tableLoading={tableLoading} shipments={shipments} />
      </div>
  );
};
export default PastShipment;
