import { Form, Input, Button, Radio, InputNumber, message, Select } from "antd";
import React, { useState } from "react";
import axios from "../config/config";
import { formConfig } from "./AppCreateShipment";

const TableSearch = ({
                         setTableFitleredContent,
                         refreshTable,
                         setTableLoading,
                         isPast,
                     }) => {
    const [form] = Form.useForm();
    const [searchCondition, setSearch] = useState({
        deliver_to: "",
        phone: "",
    });

    function handleSearchOnChange(value, e) {
        let temp = e;
        if (e.hasOwnProperty("target")) {
            temp = e.target.value;
        }
        console.log("value", value, temp);
        let newSearch = { ...searchCondition };
        newSearch[value] = temp;
        setSearch(() => newSearch);
    }

    async function handleSearch() {
        console.log("searchCondition", searchCondition);
        setTableLoading(true);
        let url = "search-shipments";
        if (isPast) {
            url += "?past=1";
        }
        let result = await axios.post(url, searchCondition);
        console.log("search result", result);
        if (result?.data?.success) {
            return setTableFitleredContent(result.data.result);
        }
        return message.error("搜索遇到错误，请稍后重试");
    }

    function clearSearchTableContent() {
        setSearch(() => {
            return {
                deliver_to: "",
                phone: "",
            };
        });
        form.resetFields();
        return refreshTable();
    }
    return (
        <>
            <Form
                wrapperCol={{ span: 14 }}
                labelCol={{ span: 2 }}
                initialValues={{ size: "default" }}
                form={form}
            >
                <Form.Item name={"deliver_to"} label="收件人">
                    <Input
                        value={searchCondition.deliver_to}
                        onChange={(e) => handleSearchOnChange("deliver_to", e)}
                    />
                </Form.Item>

                <Form.Item name={"phone"} label="手机号码">
                    <Input
                        value={searchCondition.phone}
                        onChange={(e) => handleSearchOnChange("phone", e)}
                    />
                </Form.Item>

                <Button
                    type={"primary"}
                    onClick={handleSearch}
                    style={{ marginRight: "2rem" }}
                >
                    搜索
                </Button>
                <Button type={"default"} onClick={clearSearchTableContent}>
                    Clear
                </Button>
            </Form>
        </>
    );
};

export default TableSearch;
