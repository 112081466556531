import { CSVLink } from "react-csv";
import React, { useEffect, useState } from "react";
import { IShipment } from "../../types/shipments/shipments";
import { Button, Input, Radio } from "antd";
import moment from "moment";

const ExportToExcel = ({ shipments }: { shipments: IShipment[] }) => {
    const [fileName, setFileName] = useState<string>("");
    const [selectedOption, setOption] = useState<number>(2);

    const onChangeHandler = (e) => {
        setOption(e.target.value);
    };

    useEffect(() => {
        if (selectedOption == 2) {
            let newFileName = moment().format("YYYY-MM-DD");
            setFileName(() => newFileName);
        }
    }, [selectedOption]);

    return (
        <form action="\">
            <legend>Export</legend>
            <p>How do you want to name your excel file?</p>
            <Radio.Group value={selectedOption} onChange={onChangeHandler}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <Radio value={1} style={{ margin: 10 }}>
                        Input your Excel file name:
                        <Input
                            value={fileName}
                            style={{ marginLeft: 5 }}
                            onChange={(e) => setFileName(e.target.value)}
                            disabled={shipments.length === 0 || selectedOption !== 1}
                        />
                    </Radio>
                    <Radio value={2} style={{ margin: 10 }}>
                        Use Today's Date as Excel Name
                    </Radio>
                    <CSVLink data={shipments} filename={`${fileName}.csv`}>
                        <Button type={"primary"}>Export To Excel</Button>
                    </CSVLink>
                </div>
            </Radio.Group>
        </form>
    );
};
export default ExportToExcel;
