import { Select } from "antd";
import {
    changePostAccount,
    initPostAccounts,
} from "../store/reducer/formReducer/formReducer";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../config/config";
import { message } from "antd/es";

const PostAccountSelection = () => {
    //@ts-ignore
    const formOthers = useSelector((state) => state.formOthers);

    const dispatch = useDispatch();

    useEffect(() => {
        if (formOthers.post_accounts.length === 0) {
            axios.get("get-post-accounts").then((res) => {
                console.log("getpostaccounts", res);
                if (res.data.length > 0) {
                    return dispatch(initPostAccounts(res.data));
                }
                message.error("获取邮局账户异常，默认为墨尔本东部账户");
            });
        }
    }, [formOthers.post_accounts.length]);

    const renderPostAccountSelect = () => {
        if (formOthers.post_accounts.length > 0) {
            return (
                <Select
                    //showSearch
                    style={{ width: "300px" }}
                    placeholder="Select a product account"
                    onChange={(e) => dispatch(changePostAccount(e))}
                    value={formOthers.selectedPostAccount}
                    defaultValue={"7569279"}
                >
                    {formOthers.post_accounts.map((el, index: number) => {
                        return (
                            <Select.Option value={el.account_number} key={el.account_number}>
                                {el.account_number} - {el.city_name}
                            </Select.Option>
                        );
                    })}
                </Select>
            );
        }
        return "error";
    };
    return <div style={{ width: "350px" }}>{renderPostAccountSelect()}</div>;
};

export default PostAccountSelection;
