import React, { useState } from "react";
import { Menu } from "antd";
import {
  MailOutlined,
  AppstoreOutlined,
} from "@ant-design/icons";
import AppOrder from "../../component/AppOrder";
import AppShipmentStatus from "./Shipment/AppShipmentStatus";
import PastShipment from "../../component/PastShipment";

const AppHeader = () => {
  const [state, setState] = useState<string>("shipment");
  const showSubComp = () => {
    switch (state) {
      case "shipment":
        return <AppShipmentStatus />;
      case "order":
        return <AppOrder />;
      case 'past-shipment':
        return <PastShipment/>
      default:
        return <div>loading...</div>;
    }
  };
  const changeSubComp = (e) => {
    setState(() => e.key);
  };
  return (
      <div style={{margin:'2rem'}}>
        <Menu
            onClick={changeSubComp}
            selectedKeys={[state]}
            mode="horizontal"
        >
          <Menu.Item key="shipment">
            <MailOutlined />
            Shipments
          </Menu.Item>
          <Menu.Item key="order">
            <AppstoreOutlined />
            Orders
          </Menu.Item>
          <Menu.Item key="past-shipment">
            <AppstoreOutlined />
            Past shipment
          </Menu.Item>
        </Menu>
        {showSubComp()}
      </div>
  );
};

export default AppHeader;
