import React, { useEffect, useState } from "react";
import useFetchShipment from "../../Hook/useFetchShipment";
import TableContent from "../TableContent";
import moment from "moment";

const ShipmentsTable = ({
                            shipments,
                            tableLoading,
                        }: {
    shipments?: any[];
    tableLoading: boolean;
}) => {
    const columns = [
        {
            title: "Label Url",
            dataIndex: "label_url",
            render: (text) => {
                //console.log('label', text)
                if (text) {
                    return (
                        <a href={text} target={"_blank"}>
                            View Label
                        </a>
                    );
                }
                return "暂无label";
            },
        },
        {
            title: "收件人",
            dataIndex: "deliver_to",
        },
        {
            title: "电话",
            dataIndex: "phone",
        },
        {
            title: "详细地址",
            dataIndex: "address",
        },
        {
            title: "重量",
            dataIndex: "consignment_weight",
            width: "100px",
        },
        {
            title: "包裹商品明细",
            dataIndex: "contents",
            width: "140px",
        },
        {
            title: "Value",
            dataIndex: "value",
        },
        {
            title: "Sender ID",
            dataIndex: "sender_email",
        },
        {
            title: "创建日期",
            dataIndex: "create_date",
            render: (text) => {
                return moment(text.create_date).format("MMMM Do YYYY, h:mm:ss a");
            },
            width: "150px",
        },
        {
            title: "Tracking Id",
            dataIndex: "tracking_details_consignment_id",
        },

        // {
        //     title: "国家",
        //     dataIndex: "country",
        // },

        // {
        //     title: "Product ID",
        //     dataIndex: "product_id",
        // },

        // {
        //   title: "Shipment ID",
        //   dataIndex: "shipment_id",
        // },
    ];

    return (
        <div>
            <TableContent
                shipments={shipments as any[]}
                columns={columns}
                tableLoading={tableLoading}
                setShipmentToOrder={() => {}}
            />
        </div>
    );
};
export default ShipmentsTable;
