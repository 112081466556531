const initialState = {
    actualUploadTimes: 0,
    shouldUploadTimes: 0
};

type uploadTimeType = "actualUploadTimes" | "shouldUploadTimes" | "resetUploadTimes";

export const uploadTimesReducer = (state = initialState, action: { type: uploadTimeType, payload: number }) => {
    switch (action.type) {
        case 'actualUploadTimes':
            return {
                ...state,
                actualUploadTimes: state['actualUploadTimes'] + action.payload
            };
        case "shouldUploadTimes":
            return {
                ...state,
                shouldUploadTimes: action.payload
            };
        case "resetUploadTimes":
            return {
                actualUploadTimes: 0,
                shouldUploadTimes: 0
            }
        default:
            return state;
    }
};
